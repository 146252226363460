<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form size="small" inline>
          <el-form-item label="登录名称">
            <el-input
              placeholder="请输入关键词"
              v-model="roleListPage.username"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="selectlist"
              >查询</el-button
            >
            <el-button type="primary" size="small" @click="addusersinfo"
              >添加用户</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table :data="roleList" style="width: 100%">
          <el-table-column
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
          <el-table-column prop="chineseName" label="用户名"> </el-table-column>
          <el-table-column prop="username" label="登录名"> </el-table-column>
          <el-table-column prop="mobile" label="联系方式"> </el-table-column>
          <el-table-column label="创建日期">
            <template v-slot="res">
              {{ mydateFormat(res.row.createTime, "时分秒") }}
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template v-slot="res">
              <span
                v-if="res.row.status === 1"
                class="el_spanfontstyle"
                style="color: #13d168"
                >启用</span
              >
              <span
                v-if="res.row.status === 0"
                class="el_spanfontstyle"
                style="color: #fe5a24"
                >禁用</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="res">
              <span
                class="iconfont icon-xiugai"
                @click="itemparticulars(res.row)"
              ></span>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="roleListPage.page"
          :pageSize="roleListPage.limit"
          @paging="getPaging"
        />
      </div>
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="addrole"
        @changeState="sendMsg"
        center
        width="30%"
        :title="title"
      >
        <el-form
          label-width="80px"
          :model="infoobj"
          size="small"
          :rules="rules"
          ref="addFormRef"
        >
          <el-form-item label="用户名称" prop="chineseName">
            <el-input v-model="infoobj.chineseName"></el-input>
          </el-form-item>
          <el-form-item label="登录名" prop="username">
            <el-input v-model="infoobj.username"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="mobile">
            <el-input v-model="infoobj.mobile"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="infoobj.password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input v-model="infoobj.checkPass"></el-input>
          </el-form-item>
          <el-form-item prop="chineseName">
            <el-checkbox-group v-model="infoobj.roleIdList">
              <el-checkbox v-for="item in checkitem" :label="item.roleId">
                {{ item.roleName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            class="buttons"
            size="small"
            @click="addsavemsg"
            >确 定</el-button
          >
          <el-button class="buttons" size="small" @click="addrole = false"
            >取 消</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mTitle from "@/components/mTitle";
import mDialog from "@/components/mDialog";
import mChebox from "@/components/mChebox.vue";
import mSuperIpt from "@/components/mSuperIpt";
import Pagination from "@/components/pagination.vue";
import { mapMutations, mapState } from "vuex";
export default {
  inject: ["reload"],
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.infoobj.checkPass !== "") {
          this.$refs.infoobj.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.infoobj.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      listitem: [{ name: "角色管理" }, { name: "用户列表" }],
      timeout: null,
      roleList: [],
      addrole: false,
      title: "添加用户",
      path: "/sys/user/save",
      checkitem: [],
      password1: "", //确认密码
      infomsg: "请确认密码",
      infoobj: {
        sys: "",
        chineseName: "", //用户名
        username: "", //登录名称
        mobile: "", //联系方式
        password: "", //密码
        checkPass: "",
        roleIdList: [],
        systemName: "", //用户名
      },
      roleListPage: {
        page: 1,
        limit: 10,
      },
      total: 0,
      rules: {
        chineseName: [
          { required: true, message: "请输入用户名", trigger: "change" },
        ],
        username: [
          { required: true, message: "请输入登录名", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "请输入联系方式", trigger: "change" },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  components: {
    mTitle,
    mDialog,
    mSuperIpt,
    mChebox,
    Pagination
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return (this.roleListPage.page - 1) * this.roleListPage.limit + index + 1;
    },
    getPaging(val) {
      this.roleListPage.page = val.page;
      this.roleListPage.limit = val.pageSize;
      this.getList();
    },
    async getList() {
      const { data } = await this.$http(
        "api1",
        "/sys/user/list",
        "get",
        this.roleListPage
      );
      if (!data || data.msg != "success") return;
      this.roleList = data.page.list;
      this.total = data.page.totalCount;
      const { data: data1 } = await this.$http("api1", "/sys/role/list", "get");
      if (!data1 || data1.msg != "success")
        return this.$message.error("获取数据失败");
      this.checkitem = data1.page.list;
    },
    // 修改用户
    async itemparticulars(item) {
      this.addrole = true;
      this.infoobj.userId = item.userId;
      this.infoobj.chineseName = item.chineseName;
      this.infoobj.username = item.username;
      this.infoobj.mobile = item.mobile;
      this.infoobj.systemName = item.systemName;
      this.path = "/sys/user/update";
      this.title = "修改用户";
      const {
        data: {
          user: { roleIdListString },
        },
      } = await this.$http("api1", "/sys/user/info/" + item.userId, "get");
      this.infoobj.roleIdList = roleIdListString;
    },
    // 查询用户
    selectlist() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.roleListPage.page = 1;
        this.getList();
      }, 300);
    },
    // 添加用户
    addusersinfo() {
      this.addrole = true;
      delete this.infoobj.userId;
      this.path = "/sys/user/save";
    },
    async addsavemsg() {
      const { data } = await this.$http(
        "api1",
        this.path,
        "post",
        this.infoobj
      );
      if (!data || data.msg != "success") {
        this.$message.error(data.msg);
        return false;
      }
      this.$message.success("操作成功");
      this.huifuinfoobj();
      delete this.infoobj.userId;
      this.reload();
    },
    huifuinfoobj() {
      this.infoobj = {
        sys: "",
        chineseName: "", //用户名
        username: "", //登录名称
        mobile: "", //联系方式
        password: "", //密码
        roleIdList: [],
        systemName: "system", //用户名
      };
    },
  },
};
</script>

<style lang="scss" src="@/assets/components.scss" scoped></style>
